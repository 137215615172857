<template>
  <div class="full-card has-table">
    <div class="handle-area">
      <a-button type="primary" icon="plus" @click="createShow = true" v-has-permission="['addStaff']">新增员工</a-button>
      <div class="filter margin-l20" @click="filterShow = true"></div>
    </div>
    <a-card>
      <a-table
          :columns="columns"
          :data-source="list"
          :loading="tableLoading"
          :pagination="pagination"
          rowKey="id"
          bordered
          @change="pageChange"
          :scroll="{ y: 'calc(100% - 55px)'}"
      >
        <template slot="staffName" slot-scope="text, record">
          <div @click="$util.goHidden(authority.viewStaffDetail, `/humanResources/employeeDetail?id=${record.id}`, text)" class="dominant text-underline">{{ text }}</div>
        </template>
        <template slot="joinedProjectNum" slot-scope="text, record">{{ text }}（{{ record.joinedGoingProjectNum }}）</template>
        <template slot="joinedTaskNum" slot-scope="text, record">{{ text }}（{{ record.joinedGoingTaskNum }}）</template>
        <template slot="staffStatus" slot-scope="text">
          <span v-if="text === 1">在职</span>
          <span v-if="text === 2">离职</span>
        </template>
      </a-table>
    </a-card>
    <a-drawer
        title="过滤器"
        placement="right"
        :visible="filterShow"
        @close="filterShow = false"
        width="500px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model labelAlign="right" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :model="filterForm" class="drawer-form">
          <a-form-model-item :wrapperCol="{ span: 24 }">
            <a-input-search placeholder="员工编号/姓名/联系电话/系统账号" v-model="filterForm.name" @search="onFilter" />
          </a-form-model-item>
          <a-row style="margin-top: 20px">
            <a-form-model-item label="员工状态">
              <a-select v-model="filterForm.staffStatus" placeholder="请选择员工状态">
                <a-select-option :value="1">在职</a-select-option>
                <a-select-option :value="2">离职</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="所属部门">
              <SelectDept :multiple="false" v-model="filterForm.deptId" @deptChange="deptChange"></SelectDept>
            </a-form-model-item>
            <a-form-model-item label="岗位">
              <a-select v-model="filterForm.postId" placeholder="请选择岗位">
                <a-select-option :value="item.id" v-for="(item, index) in deptList" :key="item.id">{{ item.postName }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="search" @click="onFilter">筛选</a-button>
          <a-button style="margin-left: 10px;" icon="reload" @click="onReset">重置</a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        title="新增员工"
        placement="right"
        :visible="createShow"
        @close="closeCreate"
        width="500px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="rules"
            class="drawer-form"
        >
          <a-row>
            <a-form-model-item label="员工编号" prop="staffNo">
              <a-input placeholder="请输入员工编号" v-model="form.staffNo" />
            </a-form-model-item>
            <a-form-model-item label="员工姓名" prop="staffName">
              <a-input placeholder="请输入员工姓名" v-model="form.staffName" />
            </a-form-model-item>
            <a-form-model-item  label="所属部门" prop="deptId">
              <SelectDept :multiple="false" v-model="form.deptId" @deptChange="deptChange"></SelectDept>
            </a-form-model-item>
            <a-form-model-item label="岗位">
              <a-select v-model="form.postId" placeholder="请选择岗位" prop="postId">
                <a-select-option :value="item.id" v-for="(item) in deptList" :key="item.id">{{ item.postName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="微信" prop="wechat">
              <a-input placeholder="请输入微信" v-model="form.wechat" />
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
          <a-button icon="close" class="margin-l20" @click="closeCreate">取消</a-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import SelectDept from '@/components/select/selectDept'
import { getStaffList, createStaff } from '@/api/humanResources/staff'
import { getJobList } from '@/api/humanResources/job'
import { mapState } from 'vuex'

export default {
  name: 'employeeFile',
  components: {
    SelectDept
  },
  data () {
    return {
      columns: [
        {
          title: '员工编号',
          dataIndex: 'staffNo'
        },
        {
          title: '员工姓名',
          dataIndex: 'staffName',
          scopedSlots: { customRender: 'staffName' }
        },
        {
          title: '岗位',
          dataIndex: 'postName'
        },
        {
          title: '所属部门',
          dataIndex: 'deptName'
        },
        {
          title: '参与项目',
          dataIndex: 'joinedProjectNum',
          scopedSlots: { customRender: 'joinedProjectNum' }
        },
        {
          title: '参与任务',
          dataIndex: 'joinedTaskNum',
          scopedSlots: { customRender: 'joinedTaskNum' }
        },
        {
          title: '联系电话',
          dataIndex: 'phone'
        },
        {
          title: '邮箱',
          dataIndex: 'email'
        },
        {
          title: '员工状态',
          dataIndex: 'staffStatus',
          scopedSlots: { customRender: 'staffStatus' }
        },
        {
          title: '系统账号',
          dataIndex: 'account'
        },
        {
          title: '最近修改时间',
          dataIndex: 'updateTime'
        },
        {
          title: '修改人',
          dataIndex: 'modifier'
        }
      ],
      list: [],
      total: 0,
      createShow: false,
      filterShow: false,
      filterForm: {
        pageNum: 1,
        pageSize: 10
      },
      form: {},
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      deptList: [],
      rules: {
        staffNo: [
          { required: true, message: '请输入员工编号', trigger: 'blur' }
        ],
        staffName: [
          { required: true, message: '请输入员工姓名', trigger: 'blur' }
        ],
        deptId: [
          { required: true, message: '请选择所属部门', trigger: 'change' }
        ],
        postId: [
          { required: true, message: '请选择岗位', trigger: 'change' }
        ]
      }
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      return new Promise( (resolve) => {
        this.tableLoading = true
        getStaffList(this.filterForm).then(res => {
          this.list = res.data.list
          this.pagination.total = res.data.total
          this.tableLoading = false
          resolve(res.data)
        })
      })
    },
    getJobList (deptId) {
      getJobList({
        deptId: deptId,
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.deptList = res.data.list
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
    onFilter () {
      this.filterForm.pageNum = 1
      this.getList().then(() => {
        this.filterShow = false
      })
    },
    onReset () {
      this.filterForm = {
        pageNum: 1
      }
      this.getList()
    },
    closeCreate () {
      this.form = {}
      this.createShow = false
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          createStaff(this.form).then(() => {
            this.$message.success('创建成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    },
    deptChange (value) {
      this.getJobList(value)
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
